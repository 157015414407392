import React, { Fragment, useMemo, useState, memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop, trimEnd, trimStart } from 'lodash';
import { stripHtml } from 'client/utils/string-utils';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';

import './truncated-text-lite.scss';

function getTextFragment(text, isHtml) {
  return isHtml ? <ContentFragment componentToUse="span">{text}</ContentFragment> : text;
}

function truncate({ text, maxTextLength, isFullCollapse }) {
  const isTruncateBecauseOfLength = text.length > maxTextLength;

  let indexToSplit = text.lastIndexOf(' ', maxTextLength);
  while (indexToSplit > 0 && text[indexToSplit - 1].match(/\s/)) {
    indexToSplit -= 1;
  }

  if (isFullCollapse) {
    return {
      textFirstPart: '',
      textSecondPart: text.trim(),
    };
  }

  if (!isTruncateBecauseOfLength || indexToSplit === -1) {
    return {
      textFirstPart: text.trim(),
      textSecondPart: '',
    };
  }

  return {
    textFirstPart: trimStart(text.substring(0, indexToSplit)),
    textSecondPart: trimEnd(text.substring(indexToSplit)),
  };
}

export const TruncatedTextLite = memo(
  ({
    text,
    className,
    onTruncateToggle,
    maxTextLength,
    additionalContent,
    btnClassName,
    indicatorOpenClass,
    indicatorCloseClass,
    btnTextLess,
    btnTextMore,
    btnTextA11y,
    expandTrackingId,
    expandTrackingValue,
    collapseTrackingId,
    collapseTrackingValue,
    ariaIds,
    isHtmlText,
    isFullCollapse,
    isInlineBtn,
    isOpenOnLoad,
  }) => {
    const [isCollapsed, setCollapsed] = useState(!isOpenOnLoad);
    const textToDisplay = useMemo(() => stripHtml(text), [text]);

    if (textToDisplay.length <= maxTextLength) {
      return (
        <div className={classnames('truncated-text', className)}>
          {getTextFragment(textToDisplay, isHtmlText)}
          {!!additionalContent &&
            additionalContent.map(({ content, className: additionalContentClassName }, index) => {
              const key = `additional-${index}`;

              return (
                <div key={key} className={classnames('additional-content', additionalContentClassName)}>
                  {content}
                </div>
              );
            })}
        </div>
      );
    }

    const { textFirstPart, textSecondPart } = truncate({ text: textToDisplay, maxTextLength, isFullCollapse });

    function toggleCollapse() {
      onTruncateToggle(!isCollapsed);
      setCollapsed(!isCollapsed);
    }

    const getButton = isOpenBtn => {
      const indicatorOpen = indicatorOpenClass ? <span className={indicatorOpenClass} aria-hidden /> : '';
      const indicatorClose = indicatorCloseClass ? <span className={indicatorCloseClass} aria-hidden /> : '';
      const btnId = ariaIds ? `btn-${isOpenBtn}-${ariaIds.replace(/\s+/, '-')}` : null;

      return (
        <button
          className={classnames(`truncate-button bg-white border-0 mb-0_25 p-0`, btnClassName, {
            body: !isOpenBtn,
            'btn-inline': isInlineBtn,
            'd-none': (isOpenBtn && !isCollapsed) || (!isOpenBtn && isCollapsed),
          })}
          onClick={toggleCollapse}
          id={btnId}
          aria-expanded={!isCollapsed}
          {...(ariaIds
            ? {
                'aria-labelledby': `${btnId} ${ariaIds}`,
              }
            : {})}
          {...(isOpenBtn && expandTrackingId
            ? {
                'data-tracking-id': expandTrackingId,
                'data-tracking-value': expandTrackingValue,
              }
            : {})}
          {...(!isOpenBtn && collapseTrackingId
            ? {
                'data-tracking-id': collapseTrackingId,
                'data-tracking-value': collapseTrackingValue,
              }
            : {})}
        >
          <span>
            {isOpenBtn ? btnTextMore : btnTextLess}
            {!!btnTextA11y && <span className="visually-hidden">&nbsp;{btnTextA11y}</span>}
          </span>
          {isOpenBtn ? indicatorOpen : indicatorClose}
        </button>
      );
    };

    const expandBtn = getButton(true);
    const collapseBtn = getButton(false);

    /* For accessibility it is beneficial to have two buttons, one for hiding and one for showing. */
    return (
      <div className={classnames(`truncated-text`, className, { collapsed: isCollapsed })}>
        <div>
          {!isFullCollapse && (
            <span className={classnames({ 'me-0_25': isInlineBtn && isCollapsed })}>
              {getTextFragment(textFirstPart, isHtmlText)}
              {isCollapsed && <Fragment>&hellip;</Fragment>}
            </span>
          )}

          <span className={classnames({ 'd-none': isCollapsed, 'me-0_25': isInlineBtn })}>
            {getTextFragment(textSecondPart, isHtmlText)}
          </span>
          {!!additionalContent &&
            additionalContent.map(({ content, className: additionalContentClassName }, index) => {
              const key = `additional-${index}`;

              return (
                <div
                  key={key}
                  className={classnames('additional-content', {
                    'd-none': isCollapsed,
                    [additionalContentClassName]: !isCollapsed,
                  })}
                >
                  {content}
                  {isInlineBtn && index === additionalContent.length - 1 && collapseBtn}
                </div>
              );
            })}

          {isInlineBtn && (
            <Fragment>
              {expandBtn}
              {!additionalContent && collapseBtn}
            </Fragment>
          )}
        </div>
        {!isInlineBtn && (
          <Fragment>
            <div>{expandBtn}</div>
            <div>{collapseBtn}</div>
          </Fragment>
        )}
      </div>
    );
  }
);

TruncatedTextLite.displayName = 'TruncatedTextLite';

TruncatedTextLite.propTypes = {
  text: PropTypes.string.isRequired,
  additionalContent: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.node,
      className: PropTypes.string,
    })
  ),
  onTruncateToggle: PropTypes.func,
  isInlineBtn: PropTypes.bool,
  isOpenOnLoad: PropTypes.bool,
  isFullCollapse: PropTypes.bool,
  isHtmlText: PropTypes.bool,
  btnClassName: PropTypes.string,
  btnTextLess: PropTypes.string,
  btnTextMore: PropTypes.string,
  btnTextA11y: PropTypes.string,
  className: PropTypes.string,
  indicatorCloseClass: PropTypes.string,
  indicatorOpenClass: PropTypes.string,
  maxTextLength: PropTypes.number,
  expandTrackingId: PropTypes.string,
  expandTrackingValue: PropTypes.string,
  collapseTrackingId: PropTypes.string,
  collapseTrackingValue: PropTypes.string,
  ariaIds: PropTypes.string,
};

TruncatedTextLite.defaultProps = {
  additionalContent: null,
  onTruncateToggle: noop,
  isInlineBtn: false,
  isOpenOnLoad: false,
  isFullCollapse: false,
  isHtmlText: false,
  btnClassName: 'text-gray-darker',
  btnTextLess: 'Read less',
  btnTextMore: 'Read more',
  btnTextA11y: null,
  className: null,
  indicatorCloseClass: null,
  indicatorOpenClass: null,
  maxTextLength: 85,
  expandTrackingId: null,
  expandTrackingValue: null,
  collapseTrackingId: null,
  collapseTrackingValue: null,
  ariaIds: null,
};
